:root {
    --color-primary: #A1CD3A;
    --color-primary-dark: #89AD34;
    --color-primary-light: #F8FBF2;
    --color-secondary: #23465A;
    --color-secondary-dark: #172e3b;
    --color-gray: #F1F2F4;
    --color-darkGray: #B3B4B4;
    --color-link: #7B912C;
    --color-white: #FFFFFF;
    --color-error: #B00020;
    --color-success-alert: #E4F9B9;
    --color-warning: #F2C94C;
    --color-gray-background: #DBDFE4;
    --color-gray-text: #B1B5BB;
}