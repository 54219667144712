.fieldContainer {
    width: 100%;
    margin: 24px 0;
}

.fieldContainer label {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 4px;
}

.fieldContainer .select {
    font-size: 14px;
}

.fieldContainer .select > div:first-of-type > div:first-child {
    padding: 6px 8px;
}