.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header div {
  display: flex;
  align-items: center;
}

.header button {
  margin-right: 8px;
}

.graphs, .statistics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.graphs > div, .statistics > div {
    flex-basis: 32%;
}

.statistics .graphTitle, .graphs .graphTitle {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
}

.statistics {
    margin-top: 36px;
}

.statistics .filters {
    display: flex;
    justify-content: center;
}

.statistics .filters button {
    margin-right: 4px;
    text-transform: uppercase;
    font-weight: bold;
}

.statistics .apex {
    margin-top: 8px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
}
