.block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.block > div {
    flex-basis: 48%;
    max-width: 48%;
}

.recent {
    width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

.header .numberOfProperties {
    display: flex;
}

.header .numberOfProperties .count {
    margin-left: 4px;
}

.imagePlaceholder {
    width: 120px;
    height: 75px;
}