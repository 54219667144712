.headerWrapper {
    display: unset;
}
.header {
    box-shadow: 0 2px 5px -2px var(--color-darkGray);
    padding: 8px 16px;
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    z-index: 99;
    display: flex;
    justify-content: space-between;
}

.header .left {
    display: flex;
    align-items: center;
}

.header .left:hover {
    cursor: pointer;
}

.header .right {
    display: flex;
    align-items: center;
}

.header .right .avatar {
    margin-right: 16px;
}

