.overlay {
    background: rgba(0, 0, 0, .3) !important;
}

.react-confirm-alert {
    width: 50%;
    overflow-y: scroll;
    max-height: 80vh;
    height: 100vh;
    position: absolute;
    top: 15%;
    border-radius: 8px;
}

.react-confirm-alert::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .react-confirm-alert {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }