.button {
    border: none;
    border-radius: 16px;
    padding: 12px 8px;
    transition: all linear .3s;
    min-width: 100px;
    text-align: center;
}

.button.large {
    padding: 12px 30px;
    font-size: 16px;
}

.button.breadcrumb {
    min-width: 50px;
}

.button.link {
    background: none;
    color: var(--color-link);
    text-decoration: underline;
}

.button.link:disabled {
    color: var(--color-darkGray);
    background: none;
}

.button.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.button.withImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button.-thin {
    padding: 4px 8px;
}

.button.outlined-primary-button:hover, .button.outlined-secondary-button:hover, .button.borderless-outlined-secondary-button:hover {
    background-color: var(--color-gray);
}

.button.primary-button {
    color: var(--color-white);
    background-color: var(--color-primary);
}

.button.secondary-button {
    background: var(--color-secondary);
    color: var(--color-white);
}

.button.outlined-primary-button {
    color: var(--color-primary);
    background: none;
}

.button.borderless-outlined-secondary-button {
    color: var(--color-secondary);
    background: none;
}

.button.outlined-secondary-button {
    color: var(--color-secondary);
    background: none;
    border: 1px solid var(--color-secondary);
}


.button.primary-button:hover {
    background-color: var(--color-primary-dark);
}

.button.full-width {
    width: 100%;
}

.button:hover {
    cursor: pointer;
}

.button:disabled {
    pointer-events: none;
    background: var(--color-darkGray);
}

.button.action-button {
    background: transparent;
    color: var(--color-darkGray);  
    min-width: auto;
    padding: 4px 8px;
}

.button.action-button:hover {
    background: var(--color-gray);
}