.header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
}

.header button {
    margin-right: 8px;
}

.stepper {
    margin-bottom: 24px;
}

.stepper .steps, .stepper .step {
    display: flex;
}

.stepper .step {
    margin-right: 12px;
    align-items: center;
}

.stepper .description {
    margin-left: 12px;
}

.stepper .number {
    font-size: 16px;
    font-weight: bold;
    background-color: var(--color-gray-background);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-secondary);
}

.stepper .step:not(.disabled) .number:hover {
    cursor: pointer;
}

.stepper .completed .number {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.stepper .active .number {
    border: 1px solid var(--color-primary);
}

.stepper .stepNumber {
    font-weight: bold;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 13px;
}

.stepper .stepName {
    font-size: 13px;
    color: var(--color-gray-text);
    font-weight: bold;
    white-space: nowrap;
    text-transform: capitalize;
}