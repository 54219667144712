.wrapper {
    margin: 8px 0;
}

.wrapper .editor {
    background-color: var(--color-gray);
    border: 1px solid var(--color-darkGray);
    border-radius: 4px;
    font-size: 14px;
}

.textarea {
    background-color: var(--color-gray);
    border: 1px solid var(--color-darkGray);
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    display: block;
    margin-top: 4px;
    margin-bottom: 24px;
    outline: none;
    padding: 12px 8px;
}

.wrapper .editor > div {
    padding: 0 8px;
}