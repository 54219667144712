.section {
    margin: 16px auto;
}

.section > div{
    width: 100%;
}

.section .propertyDetails .fields, .section .capitalization .fields, .section .general .fields, .section .revenue .fields, .section .expenses .fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.section .dnaButtons {
    display: flex;
    gap: 8px;
}

.section .propertyDetails .bedroomsBlock {
    display: flex;
    justify-content: space-between;
}

.section .propertyDetails .bedroomsBlock > div {
    flex-basis: 49%;
}

.section .airdna {
    margin-top: 12px;
}

.section .propertyDetails .notes {
    height: 120px;
}

.section .propertyDetails .fields > div, .section .general .fields > div {
    flex-basis: 47%;
}

.section .capitalization .fields > div, .section .revenue .fields > div, .section .expenses .fields > div {
    flex-basis: 30%;
}

@media (max-width: 1380px){
    .section .revenue .fields > div{
        flex-basis: 48%;
    }
}

.variableCostsButton {
    align-self: center;
    margin-top: 52px;
}

.variableCostsButton .hidden {
    visibility: hidden;
}

.section .zillow .fields, .section .zillow .fields > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.section .zillow .photo {
    width: 200px;
}

.section .zillow .zillowLogo {
    display: flex;
    justify-content: flex-end;
}

.section .zillow button {
    margin-left: 16px;
    margin-bottom: 23px;
    flex-basis: 30%;
    max-width: 150px;
}

.section .zillow .fields > div {
    flex-basis: 50%;
}

.createPropertyButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.projectedRent > div {
    display: flex;
    justify-content: space-between;
}

.projectedRent .field {
    width: 49%;
}

.projectedRent label {
    white-space: nowrap;
}

.expensesButtonSubtitle {
    margin: 12px 0;
}

.infoIcon {
    color: var(--color-warning);
}

.recalculateButton {
    margin-left: 16px;
}

.label {
    display: flex;
    align-items: center;
}

.label .info {
    font-size: 16px;
    margin-left: 8px;
    margin-right: 8px;
}

@media(max-width: 1606px){
    .variableCostsDialog > div {
        width: 65%;
    }
}

@media(max-width: 1236px){
    .variableCostsDialog > div {
        width: 80%;
    }
}

@media(max-width: 1004px){
    .variableCostsDialog > div {
        width: 95%;
    }
}

.closingCosts {
    display: flex;
    justify-content: space-between;
}

.closingCosts > div {
    flex-basis: 49%;
}

.variableExpensesField {
    max-width: 300px;
}
