.headerWrapper {
    display: unset;
}
.header {
    box-shadow: 0 2px 5px -2px var(--color-darkGray);
    padding: 8px 16px;
    position: sticky;
    top: 0;
    background-color: var(--color-white);
    z-index: 99;
    display: flex;
    justify-content: space-between;
}

.header .userDropdown {
    min-width: 250px;
}

.header .left {
    display: flex;
    align-items: center;
}

.header .left .logo:hover {
    cursor: pointer;
}

.header .navigation {
    margin-left: 16px;
}

.header .navigation ul {
    display: flex;
}

.header .navigation li {
    margin: 0 16px;
}

.header .navigation a {
    color: var(--color-secondary);
}

.header .navigation li:hover a {
    color: var(--color-secondary-dark);
}

.header .right {
    display: flex;
    align-items: center;
}

.header .right .avatar {
    margin-right: 16px;
}

