.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select {
    min-width: 100px;
    margin-left: 8px;
}

.paginator {
    display: flex;
    align-items: center;
}

.paginatorButton a, .previousButton a, .nextButton a {
    border: 1px solid var(--color-primary-dark);
    margin: 8px;
    padding: 8px;
    border-radius: 4px;
    display: block;
    transition: all linear .2s;
}

.previousButton a:hover, .nextButton a:hover, .paginatorButton:not(.activeButton) a:hover {
    cursor: pointer;
    background-color: var(--color-primary-light);
}

.activeButton a{
    background-color: var(--color-primary-dark);
    color: var(--color-white);
}

.disabledButton a{
    visibility: hidden;
}

.previousButton a, .nextButton a {
    font-weight: bold;
    font-size: 18px;
}
