.menu li a {
    color: var(--color-primary);
    width: 100%;
    margin: 16px 0;
    padding: 16px;
    background-color: var(--color-primary-light);
    border-radius: 8px;
    border: 1px solid var(--color-primary);
    font-weight: bold;
    display: block;
}