.buttons {
    display: flex;
}

.buttons button {
    margin: auto 4px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header > div {
    display: flex;
    align-items: center;
}

.header > div button {
    margin-right: 8px;
}

.header .title {
    display: flex;
}

.header .title .total {
    margin-left: 4px;
}

.imagePlaceholder {
    width: 120px;
    height: 75px;
}

.filters {
    display: flex;
    align-items: center;
    width: 50%;
}

.filters > div {
    width: 100%;
}

.filters button {
    margin-left: 8px;
    height: 40px;
}