.container {
    margin: 16px 0;
}

.breadcrumb a {
  background: var(--color-primary-light);
  color: var(--color-primary-dark);
  outline: none;
  padding: 0.4em 0.75em 0.4em 1.25em;
  position: relative;
  text-decoration: none;
  transition: all 0.2s linear;
}

.breadcrumb a:hover:after,
.breadcrumb a:hover {
  background: var(--color-primary-dark);
  color: var(--color-white);
}

.breadcrumb.active a,
.breadcrumb.active a:after {
    background: var(--color-primary);
    color: var(--color-white);
}

.breadcrumb a:focus:after,
.breadcrumb a:focus,
.breadcrumb a.is-active:focus {
  background: #323f4a;
  color: #fff;
}

.breadcrumb a:after,
.breadcrumb a:before {
  background: var(--color-primary-light);
  bottom: 0;
  clip-path: polygon(50% 50%, -50% -50%, 0 100%);
  content: "";
  left: 100%;
  position: absolute;
  top: 0;
  transition: all 0.2s linear;
  width: 1em;
  z-index: 1;
}

.breadcrumb a:before {
  background: var(--color-primary-dark);
  margin-left: 1px;
}

.breadcrumb a:last-child {
  border-right: none;
}

.breadcrumb a.is-active {
  background: #edf1f5;
}
