.dialog {
    color: var(--color-white);
    background-color: var(--color-primary-dark);
    padding: 36px;
}

.dialog .buttons {
    margin-top: 24px;
}

.dialog .buttons button {
    margin-left: 4px;
    margin-right: 4px;
}