.form .range {
    display: flex;
    align-items: center;
}

.form h4 {
    margin: 0;
    text-align: center;
}

.form .range > span, .form .range > div {
    margin-right: 8px;
}

.dateButtons {
    display: flex;
    margin-bottom: 8px;
}

.dateButtons button {
    margin-left: 8px;
}

.dateButtons button:first-child {
    margin-left: 28px;
}