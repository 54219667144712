.fieldContainer {
    width: 100%;
    margin: 24px 0;
}

.fieldContainer .input {
    width: 100%;
    height: auto;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: var(--color-gray);
    border: 1px solid var(--color-darkGray);
}

.fieldContainer .input span + input {
    margin-left: 8px;
    font-family: inherit !important;
    font-weight: inherit !important;
}

.fieldContainer .input input {
    background: none;
    outline: none;
    border: none;
    padding: 12px 0;
    width: 100%;
}

.fieldContainer .input input + span {
    margin-left: 8px;
}


.fieldContainer label {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 4px;
}