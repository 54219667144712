.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header > div {
    display: flex;
}

.header button {
    margin-left: 8px;
}