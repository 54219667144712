.link {
    color: var(--color-link);
    text-decoration: underline;
}

.subtitle {
    text-transform: uppercase;
    font-size: 18px;
}

.nowrap {
    white-space: nowrap;
}

.capitalized {
    text-transform: capitalize;
}

.info {
    font-size: 14px;
    color: var(--color-warning);
}

.center {
    text-align: center;
}

.field-error-label {
    color: var(--color-error);
    font-size: 12px;
}

.filled-success {
    color: var(--color-white);
    background-color: var(--color-primary);
}

.error {
    color: var(--color-error);
}

.filled-error {
    color: var(--color-white);
    background-color: var(--color-error);
}

.filled-warning {
    color: var(--color-white);
    background-color: var(--color-warning);
}

.breadcrumb {
    border-radius: 8px;
    padding: 2px 8px;
}

.copyright {
    font-weight: lighter;
    color: var(--color-gray-text);
}

.bold {
    font-weight: bold;
}