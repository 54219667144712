.table-wrapper {
    overflow-x: scroll;
    border-radius: 16px;
}

.table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

.table .empty {
    background: var(--color-gray);
}

.table .subheader {
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid var(--color-darkGray);
}

.table thead {
    background: var(--color-success-alert);
}

.table th > .sort {
    display: flex;
    align-items: center;
}

.table th > .sort div {
    display: flex;
    flex-direction: column;
    margin-left: 4px;
}

.table th > .sort div:hover {
    cursor: pointer;
}

.table th > .sort .material-icons {
    font-size: 14px;
    color: var(--color-darkGray);
}

.table th, .table td {
    padding: 16px;
    font-weight: normal;
    min-width: 135px;
}

.table tbody tr {
    background: var(--color-white);
    border-bottom: 1px solid var(--color-darkGray);
}