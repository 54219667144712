.cardWrapper {
  border-radius: 8px;
  background: var(--color-white);
  display: inline-block;
  -webkit-box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 1px 1px 8px 0px rgba(34, 60, 80, 0.2);
}

.cardWrapper.fullWidth {
  width: 100%;
}

.cardWrapper .card {
  padding: 16px;
}

.cardWrapper .title {
  background: var(--color-primary-light);
  border-radius: 8px;
  margin: 0;
  padding: 16px;
  -webkit-box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 3px 8px 0px rgba(34, 60, 80, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cardWrapper .disabled {
  color: inherit;
}

.cardWrapper .title h3 {
  margin: 0;
}

.cardWrapper .disabled {
  background: var(--color-gray-light);
}

.cardWrapper .active {
  background: var(--color-primary);
  color: var(--color-white);
}

.cardWrapper .clickable:hover {
  cursor: pointer;
}

.cardWrapper .button {
  display: flex;
}

.cardWrapper .button button {
  margin-left: 16px;
}
