.buttons {
    display: flex;
}

.buttons button {
    margin: auto 4px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .title {
    display: flex;
}

.header .title .number {
    margin-left: 4px;
}

.filters {
    display: flex;
    align-items: center;
    width: 58%;
}

.filters > div {
    width: 100%;
}

.filters button {
    margin-left: 8px;
    height: 40px;
}

.filterButton .number {
    margin-left: 4px;
}

.marketName {
    display: flex;
    align-items: center;
}

.marketName .info {
    font-size: 14px;
    margin-left: 8px;
}

.withBorder {
    border-right: 1px solid var(--color-darkGray);
}