.fieldContainer {
    width: 100%;
    margin: 24px 0;
}

.fieldContainer .input input {
    text-align: center;
    width: 100%;
    padding: 12px 25%;
    border-radius: 4px;
    background: var(--color-gray);
    border: 1px solid var(--color-darkGray);
    outline: none;
}

.fieldContainer .input input.withSuffix {
    padding-right: 30%;
}

.fieldContainer .input span {
    position: absolute;
    top: 10px;
    right: 25%;
    font-family: "Montserrat", sans-serif;
}

.fieldContainer label {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 4px;
}

.fieldContainer .inputContainer {
    position: relative;
}

.fieldContainer .inputContainer .minusButton, .fieldContainer .inputContainer .plusButton {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid var(--color-darkGray);
    font-size: 16px;
    font-weight: bold;
    color: var(--color-darkGray);
}

.fieldContainer .inputContainer .minusButton {
    top: 8px;
    left: 10%;
}

.fieldContainer .inputContainer .plusButton {
    top: 8px;
    right: 10%;
}

.fieldContainer .inputContainer .plusButton:hover, .fieldContainer .inputContainer .minusButton:hover {
    cursor: pointer;
}