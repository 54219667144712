.dropdown {
    position: relative;
}

.title {
    display: flex;
    align-items: center;
}

.title .titleText {
    color: var(--color-link);
}

.title:hover, .menu li:hover {
    cursor: pointer;
}

.menu {
    box-shadow: 0px 10px 10px var(--color-darkGray);
    position: absolute;
    background: var(--color-white);
    top: 56px;
    right: 8px;
    min-width: 150px;
    border-radius: 8px;
    padding: 4px;
    z-index: 1000;
}

.menu li{
    border-bottom: 2px solid var(--color-gray);
    padding: 12px 24px;
    display: flex;
    align-items: center;
    color: var(--color-secondary);
}

.menu li > span {
    margin-right: 4px;
}