.buttons {
    display: flex;
}

.buttons button {
    margin: auto 4px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header .title {
    display: flex;
}

.header .title .total {
    margin-left: 4px;
}

.imagePlaceholder {
    width: 120px;
    height: 75px;
}

.filters {
    display: flex;
    align-items: center;
    width: 58%;
}

.filters > div {
    width: 100%; 
}

.filters button {
    margin-left: 8px;
    height: 40px;
}

.filterButton .number {
    margin-left: 4px;
}

.statusButtons button {
    margin-right: 8px;
}

.statusButtons .active {
    background: #fff;
}

@media (min-width: 1366px) {
    .photoWithInfo {
        display: none;
    }
}

@media (max-width: 1366px) {
    .address, .status, .photo {
        display: none;
    }

    td.photoWithInfo {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    .photoWithInfo a {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}
