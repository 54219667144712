.dialog {
    background-color: var(--color-white);
    padding: 36px;
    position: relative;
}

.closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--color-darkGray);
}

.closeIcon:hover {
    cursor: pointer;
}