.content {
  padding: 16px;
  background: var(--color-gray);
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header div {
    display: flex;
    align-items: center;
}

.header button {
  margin-right: 8px;
}

.main {
  margin-top: 24px;
}

.rightButton,
.leftButton {
  position: absolute;
  bottom: 20px;
  border: none;
  border-radius: 50%;
  z-index: 10;
  width: 30px;
  height: 30px;
  padding: 0;
  transition: all linear 0.2s;
}

.rightButton {
  right: 55px;
}

.leftButton {
  right: 95px;
}

.rightButton:hover,
.leftButton:hover {
  cursor: pointer;
  background: var(--color-primary-light);
}

.original img {
  border-radius: 16px;
}

.thumbnail:focus,
.thumbnail:hover {
  border: 2px solid var(--color-primary) !important;
}

.thumbnail {
  border-radius: 12px;
  margin-bottom: 8px;
  border: 2px solid transparent !important;
}

.thumbnail:hover {
  cursor: pointer;
}

.thumbnail img {
  border-radius: 8px;
}

.copied {
  margin-right: 4px;
}

.mainInfo {
  display: flex;
  font-size: 14px;
}

.mainInfo > div:first-child {
  max-width: 400px;
}

.mainInfo .noImage {
  width: 400px;
}

.main:not(.pdfVersion) .mainInfo .table {
  padding-left: 16px;
}

.mainInfo .table h3 {
  margin: 0;
  font-size: 18px;
}

.mainInfo .table table {
  margin-top: 16px;
}

.table td {
  padding: 4px 0;
}

.table td:nth-child(2n + 1) {
  padding-right: 16px;
}

.additionalInfo {
  margin-top: 16px;
  font-size: 14px;
}

.additionalInfo .subtitle {
  margin-right: 6px;
  font-weight: bold;
}

.additionalInfo .propertyLink {
  margin-bottom: 24px;
}

.additionalInfo .propertyLink .zillow {
  margin-bottom: 8px;
}

.investmentAnalysis {
  font-size: 14px;
}

.investmentAnalysis h3 {
  margin-top: 32px;
  font-size: 18px;
}

.investmentAnalysis .subtitle {
  font-weight: bold;
}

.investmentAnalysis .metrics {
  display: flex;
  align-items: center;
  page-break-inside: avoid;
  justify-content: space-between;
}

.investmentAnalysis .metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.investmentAnalysis .metric .title {
  margin-bottom: 4px;
}

.acquisitionDetails h4, 
.proforma h4,
.graphs h4 {
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 12px;
}

.graphs h5 {
  font-size: 14px;
  color: var(--color-gray-text);
}

.graphs .graph {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-size: cover;
}

.dnaBlock {
  display: flex;
  align-items: center;
  justify-items: flex-start;
}

.dnaBlock p {
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
}

.graphs .graph .tooltip {
  background: var(--color-white);
  padding: 12px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.graphs .graph .tooltip .date {
  font-weight: 600;
}

.graphs .graph .tooltip .value {
  color: var(--color-primary);
}

.graphs .graph1 {
  min-height: 250px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .7), rgba(0, 0, 0, .3)), url("public/assets/images/graph1-placeholder.png");
}

.graphs .graphGroup {
  display: flex;
  justify-content: space-between;
}

.graphs .graphGroup > div {
  width: 49%;
}

.graphs .graph > span {
  font-size: 24px;
  font-weight: bold;
  color: var(--color-white);
}

.acquisitionDetails .table td:nth-child(2), 
.proforma .table td:nth-child(2),
.mainInfo .table td:nth-child(2) {
  padding-right: 16px;
  width: 100%;
}

.acquisitionDetails .table td:nth-child(2n + 1),
.proforma .table td:nth-child(2n + 1),
.mainInfo .table td:nth-child(2n + 1) {
  white-space: nowrap;
}

.proforma .proformaTable {
  width: 100%;
  margin-top: 16px;
}

.proforma .proformaTable th {
  text-align: left;
  padding: 4px 8px;
}

.proforma .proformaTable td {
  padding: 4px 8px;
}

.proforma .proformaTable th:first-child,
.proforma .proformaTable td:first-child {
  padding-left: 0;
}

.proforma .proformaTable th:last-child,
.proforma .proformaTable td:last-child {
  padding-right: 0;
}

@media (min-width: 1200px) {
  .main:not(.pdfVersion) {
      width: 960px;
      margin: auto;
  }
}

.generatedBy {
  margin-top: 32px;
  border: 3px solid var(--color-secondary-dark);
  padding: 16px;
  font-weight: 600;
  position: relative;
  page-break-inside: avoid;
  font-size: 14px;
}

.generatedBy .table {
  margin-top: 8px;
}

.generatedBy .table td:first-child {
  padding-right: 150px;
}

@media (max-width: 750px) {
  .generatedBy .table td:first-child {
    padding-right: 20px;
  }
}

@media (max-width: 620px) {
  .generatedBy {
    font-size: 11px;
  }
}

.generatedBy .table td:nth-child(2) {
  padding-right: 16px;
}

.generatedBy img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.generatedBy .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disclaimer .text {
  font-size: 14px;
}

.disclaimer .text p {
  margin-bottom: 1em;
}

.variableExpensesWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.variableExpensesWrapper .variableExpenses {
  width: 300px;
}

.variableExpenses h4 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 16px;
}

.variableExpenses td, .variableExpenses th {
  padding: 4px 36px;
  padding-left: 0;
}

.variableExpenses th {
  text-align: left;
}

.pdfVersion .mainInfo {
  flex-direction: column;
}

.pdfVersion .pdfImg {
  width: 210px;
  margin-bottom: 8px;
}

.pdfVersion .proforma .proformaTable {
  font-size: 12px;
}

.pdfVersion .proforma .proformaTable th, .pdfVersion .proforma .proformaTable td {
  padding: 4px 2px;
}