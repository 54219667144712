.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons > div {
    display: flex;
    align-items: center;
}

.displayed {
    width: 15px;
    height: 15px;
}

.info {
    margin-left: 8px;
    font-size: 16px !important;
}

.isDefault {
    display: flex;
    align-items: center;
}

.isDefault .info {
    padding-top: 4px;
}