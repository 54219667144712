.buttons {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.form {
  display: flex;
  flex-direction: column;
}

.buttons button {
  margin: auto 8px;
}

.uploadForm {
  min-height: 500px;
}

.input {
  margin-top: 0;
  margin-bottom: 16px;
}

.input.text > div {
  background: none;
  border: none;
  outline: none;
}

.table th {
  text-align: left;
  padding: 8px 0;
}

.table tr {
  vertical-align: top;
}

.total td:first-child {
  font-weight: bold;
}

.selectors {
  margin-bottom: 24px;
  border-bottom: 2px solid var(--color-gray);
}

.selectors .fields {
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
}

.selectors .fields > div {
  flex-basis: 30%;
  margin-bottom: 0;
  margin-right: 8px;
}

.selectors .fields > .buttons {
  flex-basis: 30%;
} 

.labelWrapper {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.labelWrapper .tooltip {
  font-size: 14px;
  margin-left: 8px;
}
