.fieldContainer {
    width: 100%;
    margin: 24px 0;
}

.fieldContainer .input {
    width: 100%;
    height: auto;
    padding: 12px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background: var(--color-gray);
    border: 1px solid var(--color-darkGray);
}