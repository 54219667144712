.avatar {
    width: 50px;
    height: 50px;
}

.avatar img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}