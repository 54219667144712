.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tabs ul {
    display: flex;
}

.tabs li {
    margin-right: 8px;
    background: var(--color-primary);
    border-radius: 8px;
    min-width: 100px;
    padding: 8px 16px;
    color: var(--color-white);
    transition: all linear .2s;
}

.tabs li:hover {
    background: var(--color-primary-dark);
    cursor: pointer;
}

.tabs li.active {
    background: var(--color-primary-light);
    color: var(--color-black);
    border: 1px solid var(--color-primary);
}

.uploadAvatar {
    display: flex;
    align-items: center;
}

.uploadAvatar .buttons {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.uploadAvatar .buttons .inputFile {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
}

.uploadAvatar .buttons button {
    margin-top: 8px;
}

.profileInfo {
    display: flex;
    justify-content: space-between;
    margin: 16px auto;
    flex-wrap: wrap;
}

.profileInfo button {
    display: block;
    margin: auto;
    margin-top: 16px;
}

.profileInfo > div {
    flex-basis: 48%;
    margin-bottom: 16px;
}

.metrics > div {
    width: 100%;
}