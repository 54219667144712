.container {
    display: flex;
    min-height: 100vh;
}

.container .leftSide {
    flex-basis: 60%;
    background-image: url('public/assets/images/registration-background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.container .rightSide {
    flex-basis: 40%;
    padding: 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 400px;
}

.container .rightSide .haveAccount {
    align-self: center;
    margin-top: 16px;
    display: flex;
}

.container .rightSide .haveAccount a {
    margin-left: 4px;
}